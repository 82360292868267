import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [email, setEmail] = useState(localStorage.getItem("Email"));

  useEffect(() => {
    const fetchUser = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
  
      const response = await fetch("/api/users/me", requestOptions);
  
      if (!response.ok) {
        setToken(null);
        setEmail(null)
      }
      
      const userData = await response.json(); // Extract JSON data from response
      const userEmail = userData.email;
  
      // Update state and localStorage with the email
      setEmail(userEmail);
      localStorage.setItem("Email", userEmail);
  
      // Update localStorage with the new token value
      localStorage.setItem("Token", token);
    };
    fetchUser();
  }, [token]);
  

  return (
    <UserContext.Provider value={{ token, setToken, email, setEmail }}>
      {props.children}
    </UserContext.Provider>
  );
};