// src/views/components/sidebar.js
import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import MicIcon from '@mui/icons-material/Mic';

const Sidebar = () => {
  const location = useLocation();
  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/' },
    { text: 'Mic Table Settings', icon: <MicIcon />, path: '/tablesettings' },
    { text: 'Atem 1 Auxiliaries', icon: <VideoSettingsIcon />, path: '/atem1auxiliaries' },
    { text: 'System Settings', icon: <SettingsIcon />, path: '/settings' },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
          backgroundColor: '#fff5ff', // Cambia lo sfondo a bianco
          color: 'white', // Cambia il colore del testo a nero
          fontStyle: 'open sans', // Cambia il font del testo
          fontSize: '2rem', // Cambia la dimensione del testo
          fontWeight: '600', // Cambia il peso del testo
        },
      }}
    >
      <List sx={{ marginTop: '80px' }}>
        {menuItems.map((item) => (
          <ListItem 
            button 
            key={item.text} 
            component={Link} 
            to={item.path}
            sx={{
              backgroundColor: location.pathname === item.path ? '#00f0f0' : 'inherit', // Evidenzia il menu selezionato
              '&:hover': {
                backgroundColor: '#00f0f0', // Cambia il colore di sfondo al passaggio del mouse
              },
            }}
          >
            <ListItemIcon sx={{ color: 'black' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Divider sx={{ backgroundColor: '#000000' }} />
    </Drawer>
  );
};

export default Sidebar;