import React, { Suspense } from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import Sidebar from './views/components/sidebar';
import RouterConfig from './routes/Router';

const theme = createTheme({
  typography: {
    fontFamily: ['"Segoe UI"'].join(','),
  },
});

function App() {
  const routing = useRoutes(RouterConfig);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <div style={{ flexGrow: 1, padding: '20px' }}>
          <Suspense fallback={<div>Loading...</div>}>
            {routing}
          </Suspense>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;