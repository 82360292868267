import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';


/* ****Pages***** */
const Dashboard = lazy(() => import('../views/Dashboard'));
const Logs = (lazy(() => import('../views/Logs')));
const TableSettings = (lazy(() => import('../views/TableSettings')));
const Atem1Auxiliaries = (lazy(() => import('../views/Atem1Auxiliaries')));


const Router = [
  {
    path: '/',
    children: [
      { path: '/', exact: true, element: <Dashboard /> },
      { path: '/logs', exact: true, element: <Logs /> },
      { path: '/tablesettings', exact: true, element: <TableSettings /> },
      { path: '/atem1auxiliaries', exact: true, element: <Atem1Auxiliaries /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  }
];

export default Router;