import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import PrivateRoute from './PrivateRoute'; // Import the PrivateRoute component
import Profile from 'src/layouts/full/header/Profile';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));
const Admin = Loadable(lazy(() => import('../layouts/admin/Admin')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')));
const Icons = Loadable(lazy(() => import('../views/icons/Icons')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Flag = Loadable(lazy(() => import('../views/flag/Flag')));
const SessionTiming = Loadable(lazy(() => import('../views/sessiontiming/SessionTiming')));
const Outfits = Loadable(lazy(() => import('../views/outfits/Outfits')));
const Tie = Loadable(lazy(() => import('../views/tie/Tie')));
const FunFact = Loadable(lazy(() => import('../views/funfact/FunFact')));
const Administrator = Loadable(lazy(() => import('../views/administrator/Administrator')));
const FlagAdmin = Loadable(lazy(() => import('../views/administrator/FlagAdmin.jsx')));
const SessionTimingAdmin = Loadable(lazy(() => import('../views/administrator/SessionTiming.jsx')));
const OutfitsAdmin = Loadable(lazy(() => import('../views/administrator/Outfits.jsx')));
const TiesAdmin = Loadable(lazy(() => import('../views/administrator/Ties.jsx')));
const FunFactAdmin = Loadable(lazy(() => import('../views/administrator/FunFact.jsx')));
const Standing = Loadable(lazy(() => import('../views/dashboard/components/Standing')));

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', exact: true, element: <PrivateRoute element={Dashboard} /> },
      { path: '/dashboard', exact: true, element: <PrivateRoute element={Dashboard} /> },
      { path: '/flag', exact: true, element: <PrivateRoute element={Flag} /> },
      { path: '/sessiontiming', exact: true, element: <PrivateRoute element={SessionTiming} /> },
      { path: '/outfits', exact: true, element: <PrivateRoute element={Outfits} /> },
      { path: '/tie', exact: true, element: <PrivateRoute element={Tie} /> },
      { path: '/funfact', exact: true, element: <PrivateRoute element={FunFact} /> },
      { path: '/icons', exact: true, element: <PrivateRoute element={Icons} /> },
      { path: '/standing', exact: true, element: <PrivateRoute element={Standing} /> },
      { path: '/profile', exact: true, element: <PrivateRoute element={Profile} /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '/auth/register', element: <Register /> },
      { path: '/auth/login', element: <Login /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/administrator',
    element: <Admin />,
    children: [
      { path: '/administrator', element: <PrivateRoute element={Administrator} /> },
      { path: '/administrator/flagadmin', element: <PrivateRoute element={FlagAdmin} /> },
      { path: '/administrator/sessiontiming', element: <PrivateRoute element={SessionTimingAdmin} /> },
      { path: '/administrator/outfits', element: <PrivateRoute element={OutfitsAdmin} /> },
      { path: '/administrator/ties', element: <PrivateRoute element={TiesAdmin} /> },
      { path: '/administrator/funfact', element: <PrivateRoute element={FunFactAdmin} /> },
    ],
  },
];

export default Router;
