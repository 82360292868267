import { element } from 'prop-types';
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Element }) => {
  const token = localStorage.getItem('Token');
  const email = localStorage.getItem('Email');
  const isAdmin = email === 'admin@admin.com';

  if (!token || token === 'null') {
    return <Navigate to="/auth/login" replace />;
  }

  if (isAdmin) {
    // Verifica se l'amministratore ha scelto la pagina flagadmin
    if (window.location.pathname === '/administrator/flagadmin') {
      return <Element />;
    } else if (window.location.pathname === '/administrator/sessiontiming') {
      return <Element />;
    } else if (window.location.pathname === '/administrator/outfits') {
      return <Element />;
    } else if (window.location.pathname === '/administrator/ties') {
      return <Element />;
    } else if (window.location.pathname === '/administrator/funfact') {
      return <Element />;
    } else {
      // Reindirizza gli altri amministratori alla pagina '/administrator'
      return (
        <>
          <Navigate to="/administrator" />
          <Element />
        </>
      );
    }
  }

  // Se l'utente non è un amministratore, renderizza solo il componente Element
  return <Element />;
};

export default PrivateRoute;
