import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

import { UserProvider } from "./context/UserContext.jsx";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserProvider>
    <Suspense>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </UserProvider>
);
